<template>
    <div>
        <Users
            :buttonPanelDetails="buttonPanelDetails"
            :counterDetails="counterDetails"
            :filterObject="filterObject"
            :userListHeader="userListHeader"
            :userListRow="userListRow"
            :menuwidth="menuwidth"
            :totalItems="totalData"
            :pageSize="pageSize"
            :page="page"
            :sortType="sortType"
            :sortOrder="sortOrder"
            :sortColIndex="sortColIndex"
            :orderByColumn="orderByColumn"
            :usersLoader="usersLoader"
            :paginationComponentKey="paginationComponentKey"
            @onClickPanelButton="onClickPanelButton"
            @multiSelectFilterChange="multiSelectFilterChange"
            @rowEditActionButton="rowEditActionButton"
            @paginateTable="paginateTable"
            @openFilterPanel="openFilterPanel"
            @clearTextFilter="clearTextFilter"
            @clearAllFilters="clearAllFilters"
            @handleSortEvent="handleSortEvent"
            @searchFilter="searchFilter"
            @dateRange="dateRange"
        ></Users>
    </div>
</template>

<script>
import store from "@/store";
import Users from "../components/Users.vue";
import { USER_ACTIONS } from "@/store/modules/userManagement/constants.js";
import get from "lodash/get";
import { mapGetters, mapState } from "vuex";

export default {
    beforeCreate() {
        this.$store = store;
    },
    name: "Users-container",
    components: {
        Users,
    },
    data() {
        return {
            counterDetails: {
                displayCounterDetails: false,
                count: 0,
                countText: "Selected",
                showVerticalLine: false,
            },
            userListRow: [],
            totalData: 0,
            page: 1,
            pageSize: 10,
            //sorting
            sortType: false,
            sortOrder: -1,
            sortColIndex: 1,
            orderByColumn: "accountCreationDate",

            userListHeader: [
                {
                    fieldName: "user",
                    text: "User",
                    width: "40%",
                    sortable: true,
                },
                {
                    fieldName: "accountCreationDate",
                    text: "Account Creation Date",
                    width: "20%",
                    sortable: true,
                    sorted: -1,
                },
                {
                    fieldName: "roles",
                    text: "Role",
                    width: "30%",
                    sortable: true,
                },
                {
                    fieldName: "status",
                    text: "Status",
                    width: "10%",
                    sortable: true,
                },
                {
                    fieldName: "edit",
                    text: "Edit",
                    width: "10%",
                    align: "right",
                },
            ],
            buttonPanelDetails: [
                {
                    id: "AddButtonId",
                    buttonName: "Add New User",
                    iconName: "plus",
                    iconColor: "defaultColor",
                    isDisabled: false,
                    showVerticalLine: true,
                    isText: false,
                },
                {
                    id: "ExportButtonId",
                    buttonName: "Export User List",
                    iconName: "share",
                    iconColor: "defaultColor",
                    isDisabled: false,
                    showVerticalLine: false,
                    isText: false,
                },
            ],

            filterObject: [
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
                {
                    filterHeader: "Status",
                    selectionType: "multiple",
                    filterList: [],
                },
            ],
            searchFilterText: "",
            filterSearchRole: [],
            filterSearchStatus: [],
            roles: "",
            status: "",
            fromAccountCreationDate:'',
            toAccountCreationDate:'',
            paginationComponentKey:0,
        };
    },
    props: {
        menuwidth: {
            type: String,
        },
    },
    mounted() {
        this.commonFunction();
        this.cachedDispatch(USER_ACTIONS.FETCH_USER_ROLES);
    },
    computed: {
        ...mapGetters(["cachedDispatch"]),
        ...mapState({
            userList(state) {
                return get(state, "userManagement.users.users", []);
            },
            usersLoader(state) {
                return get(state, "userManagement.users.__loading__", false);
            },
            userRolesList(state) {
                return get(state, "userManagement.userRoles.roles", []);
            },
            exportUsersList(state) {
                return get(state, "userManagement.ExportUsers.ExportUsers", []);
            },
        }),
    },
    methods: {
        // common function
        async commonFunction() {
            var data = {
                page: this.page,
                pageSize: this.pageSize,
                searchByUser: this.searchFilterText,
                fromAccountCreationDate: this.fromAccountCreationDate,
                toAccountCreationDate: this.toAccountCreationDate,
                roles: this.roles,
                status: this.status,
                orderByColumn: this.orderByColumn,
                isAscending: this.sortType,
            };
            await this.$store.dispatch(USER_ACTIONS.FETCH_USER_LIST, {
                data,
            });
            this.userListRow = this.userList.listUsers;
            if(this.userListRow.length == 0){
                this.userListRow = undefined;
            }
            this.totalData = this.userList.total;
        },

        //top header action button
        onClickPanelButton(val) {
            if (val === "Add New User") {
                this.addNewUser();
            } else if (val === "Export User List") {
                this.exportUserList();
            }
        },

        addNewUser() {
            this.$router.push({
                path: "/settings/user-management/add-user",
            });
        },

        // event on top export action
        async exportUserList() {
            var data = {
                page: 1,
                pageSize: 0,
                searchByUser: "",
                fromAccountCreationDate: "",
                toAccountCreationDate: "",
                roles: "",
                status: "",
                orderByColumn: "",
                isAscending: false,
            };
            await this.$store.dispatch(USER_ACTIONS.FETCH_EXPORT_USER_LIST, {
                data,
            });
            let response = this.exportUsersList;
            if (response.data !== undefined) {
                const disposition = response.headers['content-disposition'];
                let filename = '';
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(link.href);
            }
        },

        // row action button
        rowEditActionButton(value) {
            this.$router.push(`/settings/user-management/add-user?userId=${value.userId}`);
        },

        // filter-panel
        openFilterPanel(selectedFilter) {
            this.getRoleFilterList(selectedFilter);
            this.getDateRangeFilter();
            this.getUserStatusList(selectedFilter);
        },

        //  event for role filter
        async getRoleFilterList(selectedFilter) {
            let response = this.userRolesList;
            this.filterObject[1].filterHeader = "Role";
            this.filterObject[1].selectionType = "multiple";
            const data =
                response &&
                response.reduce((obj, item) => {
                    const id = item.key;
                    const lable = item.value;
                    obj.push({ id, lable,isChecked: false });
                    return obj;
                }, []);
            data.map((x) => {
                selectedFilter.forEach((y) => {
                    if (x.lable === y.value.lable) {
                        x.isChecked = true;
                    }
                });
            });
            this.filterObject[1].filterList = data;
        },

        getDateRangeFilter(){
          
            this.filterObject[0].filterHeader = "Account Creation Date";
            this.filterObject[0].selectionType = "daterange";
            const selctedFromDate = this.fromAccountCreationDate ? new Date(this.fromAccountCreationDate) : this.fromAccountCreationDate;
            const selctedToDate = this.toAccountCreationDate ? new Date(this.toAccountCreationDate) : this.toAccountCreationDate
            
              this.filterObject[0].filterList = [
                {
                    id: 1,
                    isdisabled: false,
                    lable: "from date",
                    value: selctedFromDate,
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(2049, 11, 31),
                    },
                },
                {
                    id: 2,
                    isdisabled: false,
                    lable: "to date",
                    value: selctedToDate,
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(2049, 11, 31),
                    },
                },
            ]
        },

        multiSelectFilterChange(event, value, headerTypeDetails) {
            this.page = 1;
            this.getDateRangeFilter();
            for (let i = 0; i < this.filterObject.length; i++) {
                if (this.filterObject[i].filterHeader == headerTypeDetails) {
                    for (let j = 0; j < this.filterObject[i].filterList.length; j++) {
                        if (this.filterObject[i].filterList[j].lable == value.lable) {
                            this.filterObject[i].filterList[j].isChecked = event;
                        }
                    }
                }
            }
            if (event == true) {
                this.showLoader = true;
                if (headerTypeDetails == "Role") {
                    this.filterSearchRole.push(value.lable);
                } else if (headerTypeDetails == "Status") {
                    this.filterSearchStatus.push(value.id);
                } 
                this.roles = this.filterSearchRole.join(",");
                this.status =
                    this.filterSearchStatus.join(",");
            } else if (event == false) {
                this.showLoader = true;
                if (headerTypeDetails == "Role") {
                    for (let i = 0; i < this.filterSearchRole.length; i++) {
                        if (this.filterSearchRole[i] === value.lable) {
                            this.filterSearchRole.splice(i, 1);
                        }
                    }
                } else if (headerTypeDetails == "Status") {
                    for (
                        let i = 0;
                        i < this.filterSearchStatus.length;
                        i++
                    ) {
                        if (this.filterSearchStatus[i] == value.id) {
                            this.filterSearchStatus.splice(i, 1);
                        }
                    }
                }
                this.roles = this.filterSearchRole.join(",");
                this.status =
                this.filterSearchStatus.join(",");
            }
            this.commonFunction();
            this.paginationComponentKey+=1;
        },
        searchFilter(searchText) {
             this.page = 1;
            this.searchFilterText = searchText;
            this.commonFunction();
            this.paginationComponentKey+=1;
        },

        clearTextFilter() {
            this.searchFilterText = "";
            this.page = 1;
            this.commonFunction();
            this.paginationComponentKey+=1;
        },

        clearAllFilters() {
            this.reset();
            this.clearTextFilter();
        },

        //sorting

        executeSortingLogicForMdsTable(colIndex) {
            this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

            if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
                this.$set(this.userListHeader[this.sortColIndex], "sorted", 0);
                this.sortOrder = -1;
            }

            this.sortColIndex = colIndex;
            this.$set(this.userListHeader[colIndex], "sorted", this.sortOrder);
        },

        handleSortEvent(colIndex, colName) {
            this.page = 1;
            this.executeSortingLogicForMdsTable(colIndex);
            this.orderByColumn = colName;
            this.sortOrder == 1
                ? (this.sortType = true)
                : (this.sortType = false);
            this.commonFunction();
            this.paginationComponentKey += 1;
        },


        // pagination
        paginateTable(arg) {
            this.page = arg.page;
            this.pageSize = arg.pageSize;
            this.commonFunction();
        },

        reset() {
            this.filterSearchRole = [];
            this.filterSearchStatus = [];
            this.roles = "";
            this.status = "";
            this.fromAccountCreationDate = "";
            this.toAccountCreationDate = "";
            this.getDateRangeFilter();
        },

        dateRange(fromDate, toDate) {
            this.page = 1;
            this.fromAccountCreationDate = fromDate.toLocaleDateString();
            this.toAccountCreationDate = toDate.toLocaleDateString();
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

         getUserStatusList(selectedFilter) {
            this.filterObject[2].filterHeader = "Status";
            this.filterObject[2].selectionType = "multiple";
            this.filterObject[2].filterList = [
                
                        {
                            id: 1,
                            lable: "Enabled",
                            isChecked: false,
                        },
                        {
                            id: 0,
                            lable: "Disabled",
                            isChecked: false,
                        },
                    
                    ],

            this.filterObject[2].filterList.map(x => {
                    selectedFilter.forEach(y => {
                        if (x.lable === y.value.lable) {
                            x.isChecked = true;
                        }
                    })
                });
         },
    },
};
</script>
